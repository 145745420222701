<template>
  <div class="results-products">
    <div v-if="products?.required?.length === 0"
         class="results-products__not-found"
    >
      <div class="results-products__not-found-body quote-container">
        We hebben bij jou geen verhoogd risico op een soa gevonden.
      </div>
    </div>
    <div v-if="predictionData">
      <div class="results-products__group">
        <DiseasePredictorComponent
            :mobile-info-button-settings="mobileInfoButtonSettings"
            :prediction-data="predictionData"
            :products="products.required ?? []"/>
      </div>
    </div>
    <div v-for="(typeProducts, type, index) in products" :key="type">
      <div v-if="typeProducts.length > 0"
           class="results-products__group">
        <div v-if="products.required.length === 0 && type === 'recommended'"
             class="results-products__recommended-title-wrapper"
        >
          <span class="results-products__recommended-title">Overweeg bij twijfel</span>
        </div>
        <transition name="fade" v-if="type === 'common'">
            <AccordionComponent
                :border="Object.keys(products).length - 1 === index ? 'both' : 'top'"
                :title="'Niet aanbevolen testen'">
              <div class="results-products__list row">
                <ResultProductComponentV2
                    v-for="product in typeProducts"
                    :key="product.id"
                    extra-classes="col-12 col-lg-6"
                    v-bind="{ product, type }"/>
              </div>
            </AccordionComponent>
        </transition>
        <div v-else>
          <AccordionComponent
              :cart-item-delete-open="true"
              :border="Object.keys(products).length - 1 === index ? 'both' : 'top'"
              :title="'Aanbevolen testen'">
            <div class="results-products__list row">
              <ResultProductComponentV2
                  v-for="product in typeProducts"
                  :key="product.id"
                  extra-classes="col-12 col-lg-6"
                  v-bind="{ product, type }"/>
            </div>
          </AccordionComponent>
        </div>
        <CartComponent
            v-if="type === 'common'"
            :cart-info="true"
            :show-delete-button="true"
            :cart-action="true"
            :agreement-link="agreementLink"
            :informedconsent-heading="informedconsentHeading"
            :informedconsent-cont="informedconsentCont"
            action-text="Ga verder"
            action="/checkout"
            class="d-block d-md-none pb-5 mt-5 mt-md-0"
            :id="cartId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import dangerProducts from '../helpers/dangerProducts';
import ResultProductComponentV2 from './ResultProductComponentV2.vue';
import CartComponent from './CartComponent.vue';
import getGtmProduct from '../helpers/gtmProduct';
import AccordionComponent from './common/Accordion';
import DiseasePredictorComponent from './DiseasePredictorComponent.vue';

export default {
  name: 'ResultProductsComponent',
  components: {
    ResultProductComponentV2,
    DiseasePredictorComponent,
    CartComponent,
    AccordionComponent,
  },
  data() {
    return {
      products: [],
      zizId: '',
      show: false,
      predictionData: null,
      cartId: 'checkout-cart',
    };
  },
  props: {
    getProductsLink: {
      type: String,
      default: '/calculate/products',
    },
    getPredictionLink: {
      type: String,
      default: '/calculate/prediction',
    },
    agreementLink: {
      type: Object,
      default: null,
    },
    informedconsentCont: {
      type: String,
      default: '',
    },
    informedconsentHeading: {
      type: String,
      default: '',
    },
    consultAction: {
      type: String,
      default: '/consult',
    },
  },
  computed: {
    ...mapGetters(['ANAMNESIS', 'IS_ANAMNESIS_VALID']),

    showList() {
      if (this.show) {
        return 'Verberg niet aanbevolen testen';
      }

      return 'Bekijk niet aanbevolen testen';
    },
    mobileInfoButtonSettings() {
      return {
        title: 'Direct naar zorgpakket',
        href: `${window.location.pathname}#${this.cartId}`,
      };
    },
  },
  mounted() {
    if (!this.IS_ANAMNESIS_VALID) {
      window.location.href = this.consultAction;

      return;
    }

    const loadingBlock = this.$parent.$refs.loading;

    this.$http.post(this.getProductsLink, this.ANAMNESIS)
      .then((data) => {
        this.zizId = data.data.zizId;
        this.products = {
          recommended: data.data.recommended,
          ...data.data,
        };

        delete this.products.header;
        delete this.products.zizId;
        this.pageUpdate();
        this.changePageHeading();

        if (this.products?.required) {
          this.products?.required.forEach((requiredProduct) => {
            this.$store.commit('ADD_TO_CART', requiredProduct);
          });
        }

        // Save all products in store
        const allProducts = [].concat(...Object.values(data.data)
          .filter((a) => a instanceof Array));
        this.$store.commit('SET_ALL_PRODUCTS', allProducts);

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'view_item_list',
          ecommerce: {
            items: allProducts.map((product, i) => getGtmProduct(product, i)),
          },
        });
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'view_promotion',
          ecommerce: {
            items: allProducts.map((product, i) => getGtmProduct(product, i)),
          },
        });

        if (this.products.required.length > 0) {
          return this.$http.post(this.getPredictionLink, this.ANAMNESIS);
        }

        return { data: { result: 'ok', prediction: null } };
      })
      .then((response) => {
        if (response.data.result === 'ok') {
          this.predictionData = response.data.prediction;
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = this.consultAction;
      })
      .finally(() => {
        loadingBlock.classList.add('d-none');
        this.$store.state.loading = false;
      });
  },
  methods: {
    pageUpdate() {
      if (this.products.risk > 0) {
        this.$parent.$refs.content.classList.add('danger');
      }
    },
    changePageHeading() {
      /* eslint-disable-next-line */
      for (const products of Object.values(this.products)) {
        /* eslint-disable-next-line */
        for (const product of Object.values(products)) {
          const l = product.letter;
          if (dangerProducts.some((letter) => letter === l) && product.risk > 0) {
            break;
          }
        }
      }
    },

  },
};
</script>

<style>
  html {
    scroll-behavior: smooth;
  }
</style>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
