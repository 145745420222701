<template>
  <a v-if="link.length > 0"
     :href="link"
     class="info-button-link"
  >
    <button class="info-button">{{title}}</button>
  </a>
  <button v-else class="info-button">{{title}}</button>
</template>

<script>
export default {
  name: 'InfoButton',
  props: {
    title: {
      type: String,
      default: 'Meer informatie',
    },
    href: {
      type: String,
      default: '',
    },
  },
  computed: {
    link() {
      return this.href.trim();
    },
  },
};
</script>

<style scoped lang="scss">
  .info-button-link,
  .info-button {
    width: 100%;
  }

  .info-button {
    cursor: pointer;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    height: 2.5rem;
    border-color: transparent;
    border-radius: 8px;
    background: var(--Colors-Green-green-500, #02BD72);
    box-shadow: 0 3px 0 0 #018A53;
  }
</style>
